import request from '@/services/request'
export function index(params) {
  return request({
    url: '/settings',
    method: 'get',
    params,
  })
}
export function update(id, data) {
  return request({
    url: `/settings/${id}`,
    method: 'put',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
}
