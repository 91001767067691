<template>
  <v-container fluid class="px-5">
    <v-row>
      <v-col cols="12">
        <h1 class="font-weight-medium">
          <span>Help & Support</span>
        </h1>
      </v-col>
      <v-col cols="12" md="8" lg="6" style="height: calc(100vh - 240px); overflow: auto">
        <v-card outlined class="mb-5" v-for="setting in settings" :key="setting.id">
          <v-card-title class="d-flex py-2"
            >{{ setting.name }}
            <v-btn @click="show(setting)" color="primary" icon class="ml-auto" small v-if="role === 'admin'"
              ><v-icon>{{ icons.mdiPencil }}</v-icon></v-btn
            >
          </v-card-title>
          <v-card-text class="pb-2">
            <template v-if="setting.value">
              <span v-if="setting.type === 'text'">{{ setting.value }}</span>
              <a :href="setting.value.split('|')[1]" target="_blank" v-if="setting.type === 'link'">{{
                setting.value.split('|')[0]
              }}</a>
              <a :href="'mailto:' + setting.value" target="_blank" v-if="setting.type === 'email_address'">{{
                setting.value
              }}</a>
              <a :href="'tel:' + setting.value" target="_blank" v-if="setting.type === 'phone_number'">{{
                setting.value
              }}</a>
              <a :href="setting.value" target="_blank" v-if="setting.type === 'file'">{{
                setting.value.split('/').pop()
              }}</a>
            </template>
            <span v-else>Not configured</span>
          </v-card-text>
        </v-card>
        <h3 v-if="settings.length === 0">No setting found</h3>
      </v-col>
      <v-col cols="12" md="4" lg="6" class="d-none d-md-flex justify-center">
        <v-img
          contain
          max-width="170"
          src="@/assets/images/3d-characters/pose-m-1.png"
          class="security-character"
        ></v-img>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="showDialog" max-width="450">
      <v-card>
        <v-card-title>Update Setting</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-select v-model="form.type" label="Type" :items="types" @change="onTypeChanged"></v-select>
            <template v-if="form.type === 'link'">
              <v-textarea
                @change="updateLink"
                rows="2"
                v-model="form.title"
                label="Title"
                :rules="[v => !!v || 'This field is required']"
              ></v-textarea>
              <v-text-field
                @change="updateLink"
                v-model="form.href"
                label="Link"
                :rules="[v => !!v || 'This field is required']"
              >
              </v-text-field>
            </template>
            <file-upload
              v-else-if="form.type === 'file' && showDialog"
              :extensions="extensions.map(e => e.name)"
              :accept="extensions.map(e => '.' + e.name).join(',')"
              style="border-radius: 9px; width: 100%; height: 130px; border: 1px dashed var(--v-primary-base)"
              v-model="files"
              @input-filter="inputFilter"
              :drop="true"
            >
              <template>
                <div style="cursor: pointer; transform: translate(0, 50%)">
                  <v-icon>{{ icons.mdiFileUploadOutline }}</v-icon>
                  <p class="mt-2 font-weight-bold">Drag file to here or browse</p>
                </div>
                <p
                  style="position: absolute; bottom: 0; width: 100%; font-size: 13px; z-index: 2"
                  class="mb-0 text-center py-2"
                  v-if="files.length"
                >
                  {{ files[0].name }} ({{ files[0].size | size }})
                  <v-icon @click="files = []" size="16" color="error">{{ icons.mdiDeleteOutline }}</v-icon>
                </p>
              </template>
            </file-upload>
            <v-textarea
              v-else
              rows="2"
              v-model="form.value"
              label="Value"
              :rules="[v => !!v || 'This field is required']"
            ></v-textarea
          ></v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="update" text>Update</v-btn>
          <v-btn color="primary" text @click="showDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mdiPlus, mdiPencil } from '@mdi/js'
import { index, update } from '@/api/setting'
import VueUploadComponent from 'vue-upload-component'
export default {
  components: { 'file-upload': VueUploadComponent },
  data() {
    return {
      icons: { mdiPlus, mdiPencil },
      showDialog: false,
      form: { id: undefined, type: '', value: '', href: '', title: '' },
      settings: [],
      types: ['text', 'file', 'link', 'phone_number', 'email_address'].map(type => ({
        value: type,
        text: type.split('_').join(' '),
      })),
      files: [],
      valid: true,
      extensions: [
        {
          name: 'pdf',
          size: 5 * 1024 * 1024,
        },
      ],
    }
  },
  computed: {
    role() {
      return this.$store.state.auth.user?.role?.code
    },
  },
  watch: {
    files() {
      this.form.value = this.files[0].file
    },
  },
  methods: {
    onTypeChanged() {
      this.form.value = ''
      this.form.title = ''
      this.form.href = ''
    },
    updateLink() {
      this.form.value = `${this.form.title}|${this.form.href}`
    },
    reset() {
      this.form = {
        id: undefined,
        type: '',
        value: '',
      }
    },
    show(setting) {
      this.form = {
        id: setting.id,
        type: setting.type,
        value: setting.value,
      }
      this.form.title = setting.type === 'link' ? setting.value.split('|')[0] : ''
      this.form.href = setting.type === 'link' ? setting.value.split('|')[1] : ''
      this.showDialog = true
    },

    async getSettings() {
      this.$loader(true)
      const { data } = await index()
      this.settings = data
      this.$loader(false)
    },

    async update() {
      await this.$refs.form.validate()
      if (!this.valid) return
      try {
        this.$loader(true)
        const form = new FormData()
        form.append('type', this.form.type)
        form.append('value', this.form.value)
        await update(this.form.id, form)
        await this.getSettings()
        this.showDialog = false
        this.$message('Setting updated successfully', 'success')
      } finally {
        this.$loader(false)
      }
    },
    inputFilter(file, _, prevent) {
      const extension = this.extensions.find(e => e.name === file.name.split('.').pop())
      if (!extension) {
        this.$message(`Invalid file type`, 'error')
        prevent()
      }
      if (file.size > extension.size) {
        this.$message(`File size is too large`, 'error')
        prevent()
      }
      file.type = extension.type
    },
  },

  created() {
    this.getSettings()
  },
}
</script>
